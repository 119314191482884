import { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ValidateField } from '../../helpers/ValidatorHelper';
import FowButton from '../../components/common/FowButton';
import InvoiceSearch from '../../components/invoices/InvoiceSearch';
import InvoiceList from '../../components/invoices/InvoiceList';
import { IInvoicePagination, IInvoicePayload } from '../../datatypes/Invoice';
import { fetchInvoices } from '../../redux/slices/invoiceSlice';
import {
  invoiceListPagination,
  invoiceValidations,
  searchInvoiceField,
} from '../../constants/Invoice';
import { formatDate } from '../../helpers/common';
import { useNavigate } from 'react-router-dom';

const InvoiceListing = () => {
  const [invoiceSearch, setInvoiceSearch] = useState({ ...searchInvoiceField });

  const [lastValidInvoiceSearch, setLastValidInvoiceSearch] = useState({
    ...searchInvoiceField,
  });
  const [errors, setErrors] = useState({ ...searchInvoiceField });

  const [showAdvanceSearch, setShowAdvanceSearch] = useState({
    show: false,
    filterApplied: false,
  });
  const [pagination, setPagination] = useState<IInvoicePagination>(
    invoiceListPagination
  );

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { invoiceList } = useAppSelector<IInvoicePayload>(
    (state) => state.invoice
  );

  useEffect(() => {
    dispatch(
      fetchInvoices({ params: { ...pagination, ...lastValidInvoiceSearch } })
    );
    // eslint-disable-next-line
  }, [pagination]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setInvoiceSearch((invoiceSearch) => ({ ...invoiceSearch, [name]: value }));

    let tempValue: any = value;
    if (name === 'payment_date_from') {
      tempValue = {
        value1: value,
        value2: invoiceSearch.payment_date_to,
      };
    } else if (name === 'payment_date_to') {
      tempValue = {
        value1: value,
        value2: invoiceSearch.payment_date_from,
      };
    }
    const error =
      value === ''
        ? null
        : ValidateField(name, tempValue, invoiceValidations?.[name]);

    setErrors((errors) => ({ ...errors, [name]: error || '' }));
  };

  const toggleAdvanceFilter = () => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      show: !showAdvanceSearch.show,
    });
  };

  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      filterApplied: isApplied,
    });
  };

  const validateForm = () => {
    let hasValuesInSearchInvoice = false;

    if (Object.keys(invoiceSearch).some((key) => invoiceSearch[key] !== '')) {
      hasValuesInSearchInvoice = true;
    } else if (
      JSON.stringify(lastValidInvoiceSearch) !== JSON.stringify(invoiceSearch)
    ) {
      hasValuesInSearchInvoice = true;
    }

    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValuesInSearchInvoice || hasErrors;
  };

  const onInvoiceSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      const tempInvoiceSearch = {
        ...invoiceSearch,
        page: 1,
        payment_date_from: invoiceSearch.payment_date_from
          ? formatDate(invoiceSearch.payment_date_from, 'MM/DD/YYYY')
          : '',
        payment_date_to: invoiceSearch.payment_date_to
          ? formatDate(invoiceSearch.payment_date_to, 'MM/DD/YYYY')
          : '',
      };
      setLastValidInvoiceSearch({ ...invoiceSearch });
      dispatch(fetchInvoices({ params: tempInvoiceSearch }));
      toggleFilterApplied(true);
    }
  };

  const resetInvoiceSearch = () => {
    setLastValidInvoiceSearch({ ...searchInvoiceField });
    dispatch(fetchInvoices({ params: invoiceListPagination }));
    toggleFilterApplied(false);
    setInvoiceSearch({ ...searchInvoiceField });
  };

  const handleGenerateSearchPinCodeButtonClick = () => {
    navigate('/invoices/generate-search-pin-code');
  };

  return (
    <div className="invoices">
      <PageTitle
        heading="Invoices"
        buttonName="Generate Search Pincode"
        buttonClick={handleGenerateSearchPinCodeButtonClick}
      />
      <div className="invoices-content">
        <div className="control-top-button mb-2">
          <FowButton
            variant="primary"
            className="mr-2"
            onClick={() => toggleAdvanceFilter()}
          >
            Advanced Search
          </FowButton>
        </div>
        <div>
          {showAdvanceSearch.show && (
            <InvoiceSearch
              errors={errors}
              handleInputChange={handleInputChange}
              invoiceSearch={invoiceSearch}
              onInvoiceSearch={onInvoiceSearch}
              resetInvoiceSearch={resetInvoiceSearch}
              isFilterApplied={showAdvanceSearch.filterApplied}
            />
          )}
          <InvoiceList
            invoiceList={invoiceList}
            setPagination={setPagination}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceListing;
