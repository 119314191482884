import { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { ValidateField } from '../../helpers/ValidatorHelper';
import FowButton from '../../components/common/FowButton';
import {
  IDeathNoticePagination,
  IDeathNoticePayload,
} from '../../datatypes/DeathNotice';
import {
  fetchDeathNotices,
  resetDeathNoticeData,
} from '../../redux/slices/deathNoticeSlice';
import {
  deathNoticeListPagination,
  deathNoticeValidations,
  searchDeathNoticeField,
} from '../../constants/DeathNotice';
import DeathNoticeSearch from '../../components/death-notices/DeathNoticeSearch';
import DeathNoticeList from '../../components/death-notices/DeathNoticeList';

const DeathNoticeListing = () => {
  const [deathNoticeSearch, setDeathNoticeSearch] = useState({
    ...searchDeathNoticeField,
  });
  const [errors, setErrors] = useState({ ...searchDeathNoticeField });
  const [showAdvanceSearch, setShowAdvanceSearch] = useState({
    show: false,
    filterApplied: false,
  });
  const [pagination, setPagination] = useState<IDeathNoticePagination>(
    deathNoticeListPagination
  );
  const dispatch = useAppDispatch();
  const { deathNoticeList, deleteNoticeDetails } =
    useAppSelector<IDeathNoticePayload>((state) => state.deathNotice);

  useEffect(() => {
    if (!deleteNoticeDetails.loading && deleteNoticeDetails.success) {
      dispatch(fetchDeathNotices({}));
      dispatch(resetDeathNoticeData());
    }
    // eslint-disable-next-line
  }, [deleteNoticeDetails.loading, dispatch]);

  useEffect(() => {
    dispatch(fetchDeathNotices({ params: pagination }));
  }, [pagination, dispatch]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setDeathNoticeSearch((deathNoticeSearch) => ({
      ...deathNoticeSearch,
      [name]: value,
    }));

    let tempValue: any = value;
    if (name === 'created_date_from') {
      tempValue = {
        value1: value,
        value2: deathNoticeSearch.created_date_to,
      };
    } else if (name === 'created_date_to') {
      tempValue = {
        value1: value,
        value2: deathNoticeSearch.created_date_from,
      };
    }
    const error =
      value === ''
        ? null
        : ValidateField(
            e.target.name,
            tempValue,
            deathNoticeValidations?.[name]
          );

    setErrors((errors) => ({ ...errors, [name]: error || '' }));
  };

  const toggleAdvanceFilter = () => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      show: !showAdvanceSearch.show,
    });
  };

  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      filterApplied: isApplied,
    });
  };

  const validateForm = () => {
    const hasValuesInvoiceSearch = Object.keys(deathNoticeSearch).some(
      (key) => deathNoticeSearch[key] !== ''
    );
    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValuesInvoiceSearch || hasErrors;
  };

  const onDeathNoticeSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      const tempDeathNoticeSearch = {
        ...deathNoticeSearch,
        page: 1,
      };
      dispatch(fetchDeathNotices({ params: tempDeathNoticeSearch }));
      toggleFilterApplied(true);
    }
  };

  const resetDeathNoticeSearch = () => {
    dispatch(fetchDeathNotices({}));
    toggleFilterApplied(false);
    setDeathNoticeSearch({ ...searchDeathNoticeField });
  };

  return (
    <div className="missing-wills">
      <PageTitle heading="Death Notices" />
      <div className="missing-wills-content">
        <div className="control-top-button mb-2">
          <FowButton
            variant="primary"
            className="mr-2"
            onClick={() => toggleAdvanceFilter()}
          >
            Advanced Search
          </FowButton>
        </div>
        <div>
          {showAdvanceSearch.show && (
            <DeathNoticeSearch
              errors={errors}
              handleInputChange={handleInputChange}
              deathNoticeSearch={deathNoticeSearch}
              onDeathNoticeSearch={onDeathNoticeSearch}
              resetDeathNoticeSearch={resetDeathNoticeSearch}
              isFilterApplied={showAdvanceSearch.filterApplied}
            />
          )}
          <DeathNoticeList
            deathNoticeList={deathNoticeList}
            setPagination={setPagination}
          />
        </div>
      </div>
    </div>
  );
};

export default DeathNoticeListing;
