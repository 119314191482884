import { useEffect, useState } from 'react';
import PageTitle from '../../components/common/PageTitle';
import SearchForm from '../../components/will-creators/SearchForm';
import Listing from '../../components/will-creators/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import {
  fetchwillCreators,
  resetWillCreatorData,
} from '../../redux/slices/willCreatorSlice';
import {
  willCreatorsPagination,
  initialWillCreatorSearchField,
  willCreatorSearchFieldValidations,
} from '../../constants/WillCreator';
import { ValidateField } from '../../helpers/ValidatorHelper';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import {
  IWillCreatorPayload,
  IShowDeleteWillCreatorModal,
  IWillCreatorPagination,
} from '../../datatypes/WillCreator';
import FowButton from '../../components/common/FowButton';

const WillCreatorsListing = () => {
  const [searchWill, setSearchWill] = useState({
    ...initialWillCreatorSearchField,
  });
  const [lastValidSearch, setLastValidSearch] = useState({
    ...initialWillCreatorSearchField,
  });
  const [errors, setErrors] = useState({ ...initialWillCreatorSearchField });
  const [showAdvanceSearch, setShowAdvanceSearch] = useState({
    show: false,
    filterApplied: false,
  });

  const [pagination, setPagination] = useState<IWillCreatorPagination>(
    willCreatorsPagination
  );

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteWillCreatorModal>({
      show: false,
      idDetails: [],
    });

  const dispatch = useAppDispatch();

  const { willCreators, deleteWillCreatorStatus } =
    useAppSelector<IWillCreatorPayload>((state) => state.willStatus);

  const getwillCreatorsData = () => {
    dispatch(fetchwillCreators({ params: pagination }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setSearchWill((searchWill) => ({ ...searchWill, [name]: value }));

    let tempValue: any = value;
    if (name === 'created_from') {
      tempValue = {
        value1: value,
        value2: searchWill.created_to,
      };
    } else if (name === 'created_to') {
      tempValue = {
        value1: value,
        value2: searchWill.created_from,
      };
    }

    const error =
      value === ''
        ? null
        : ValidateField(
            e.target.name,
            tempValue,
            willCreatorSearchFieldValidations?.[name]
          );

    setErrors((errors) => ({ ...errors, [name]: error || '' }));
  };

  const toggleAdvanceFilter = () => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      show: !showAdvanceSearch.show,
    });
  };

  const toggleFilterApplied = (isApplied: boolean) => {
    setShowAdvanceSearch({
      ...showAdvanceSearch,
      filterApplied: isApplied,
    });
  };

  const validateForm = () => {
    let hasValuesInSearchWill = false;

    if (Object.keys(searchWill).some((key) => searchWill[key] !== '')) {
      hasValuesInSearchWill = true;
    } else if (JSON.stringify(lastValidSearch) !== JSON.stringify(searchWill)) {
      hasValuesInSearchWill = true;
    }

    const hasErrors = Object.keys(errors).some((key) => errors[key] !== '');
    return !hasValuesInSearchWill || hasErrors;
  };

  const onWillSearch = () => {
    const hasError = validateForm();
    if (!hasError) {
      setLastValidSearch(searchWill);
      dispatch(
        fetchwillCreators({
          params: { ...searchWill, ...pagination, page: 1 },
        })
      );
      toggleFilterApplied(true);
    }
  };

  const resetWillSearch = () => {
    setLastValidSearch(initialWillCreatorSearchField);
    dispatch(fetchwillCreators({ params: pagination }));
    toggleFilterApplied(false);
    setSearchWill({ ...initialWillCreatorSearchField });
  };

  //handle successful delete api call and modal closing
  useEffect(() => {
    if (deleteWillCreatorStatus.success) {
      toastSuccessMessage('Will deleted successfully!');

      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetWillCreatorData());
      getwillCreatorsData();
    }
    // eslint-disable-next-line
  }, [deleteWillCreatorStatus]);

  //handle page change
  useEffect(() => {
    dispatch(
      fetchwillCreators({ params: { ...pagination, ...lastValidSearch } })
    );
    // eslint-disable-next-line
  }, [pagination]);

  return (
    <div className="will-status">
      <PageTitle heading="Will Creators" />
      <div className="will-status-content">
        <div className="control-top-button mb-2">
          <FowButton
            variant="primary"
            className="mr-2"
            onClick={() => toggleAdvanceFilter()}
          >
            Advanced Search
          </FowButton>
        </div>
        <div>
          {showAdvanceSearch.show && (
            <SearchForm
              errors={errors}
              handleInputChange={handleInputChange}
              searchWill={searchWill}
              onWillSearch={onWillSearch}
              resetWillSearch={resetWillSearch}
              isFilterApplied={showAdvanceSearch.filterApplied}
            />
          )}
          <Listing
            willCreators={willCreators}
            showDeleteModal={showDeleteModal}
            setShowDeleteModal={setShowDeleteModal}
            deleteWillStatusDetail={deleteWillCreatorStatus}
            setPagination={setPagination}
          />
        </div>
      </div>
    </div>
  );
};

export default WillCreatorsListing;
