import moment from 'moment';

export const formatDate = (
  dateString: string,
  currentFormat: string = 'DD/MM/YYYY'
) => {
  if (!dateString) {
    return ''; // Handle empty date case
  }

  const parsedDate = moment(dateString, currentFormat, true);

  if (!parsedDate.isValid()) {
    return ''; // Handle invalid date case
  }

  return parsedDate.format('YYYY-MM-DD');
};

export const formatLabel = (label: string) => {
  switch (label) {
    case 'creditcard':
      return 'Credit Card';
    case 'pending':
      return 'Pending';
    default:
      return label;
  }
};

export const statusBadgeBackground = (name: string) => {
  switch (name) {
    case 'pending':
      return 'badge badge-pill badge-primary py-1';
    default:
      return 'badge badge-pill badge-secondary py-1';
  }
};

// functions to help data manipulation and formatting

export function deleteEmptyKeys(obj: any) {
  if (typeof obj === 'object') {
    for (let key in obj) {
      if (
        obj.hasOwnProperty(key) &&
        (obj[key] === null || obj[key] === undefined || obj[key] === '')
      ) {
        delete obj[key];
      }
    }
  }
  return obj;
}

export const isMinor = (date: string) => {
  return moment().diff(date, 'years', false) < 18;
};

export const formatNumber = (val: number, unit: string) => {
  switch (unit) {
    case '%':
      return `${Number(val)}%`;
    case '$':
      return `${Number(val)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
  }
};
