import useAxiosService from '../services/axios.service';

const useCharityPartnershipApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //api call for charities list
  const getCharityPartnerships = async (params?: any) => {
    return await getUserHttpClient()
      .get('/partnerships', { params: params ? params : {} })
      .then((response) => response?.data);
  };

  //api call for charity Partnership by id
  const getCharityPartnershipDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/partnerships/' + id)
      .then((response) => response?.data);
  };

  //api call for save charity Partnerships
  const saveCharityPartnershipDetails = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/partnerships/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response?.data);
  };

  //api call for update charities
  const updateCharityPartnershipDetails = async (data: any, id: string) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/partnerships/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response?.data);
  };

  return {
    getCharityPartnerships,
    saveCharityPartnershipDetails,
    updateCharityPartnershipDetails,
    getCharityPartnershipDetails,
  };
};

export default useCharityPartnershipApi;
