import { IWillCreatorSearchField } from '../datatypes/WillCreator';

export const willCreatorsPagination = {
  per_page: 50,
  page: 1,
};

export const initialWillCreatorSearchField: IWillCreatorSearchField = {
  first_name: '',
  last_name: '',
  email: '',
  city: '',
  state_id: '',
  county_id: '',
  zipcode: '',
  created_from: '',
  created_to: '',
};

export const willCreatorSearchFieldValidations: IWillCreatorSearchField = {
  first_name: 'nameValidate|min:2',
  last_name: 'nameValidate|min:2',
  email: 'email',
  city: 'min:2',
  state: '',
  county: '',
  zipcode: 'zipcode',
  created_from: 'date:MM-DD-YYYY',
  created_to: 'date:MM-DD-YYYY|compareDateMore',
};
