import useAxiosService from '../services/axios.service';

const useCharitiesApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const stellate_api_url = process.env.REACT_APP_STELLATE_API_URL;
  const stellate_api_token = process.env.REACT_APP_STELLATE_API_TOKEN;

  //api call for charities list
  const getCharities = async (params?: any) => {
    return await getUserHttpClient()
      .get('/charities', { params: params ? params : {} })
      .then((response) => response?.data);
  };

  //api call for charities by id
  const getCharityDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/charities/' + id)
      .then((response) => response?.data);
  };

  //api call for save charities
  const saveCharityDetails = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/charities/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response?.data);
  };

  //api call for update charities
  const updateCharityDetails = async (data: any, id: string) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient()
      .post('/charities/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response?.data);
  };

  //api call for delete charities
  const deleteCharity = async (id: string) => {
    return await getUserHttpClient()
      .delete('/charities/' + id)
      .then((response) => response?.data);
  };

  //api call for charity categories list
  const getCharityCategories = async (params?: any) => {
    return await getUserHttpClient()
      .get('/charity-categories', { params: params ? params : {} })
      .then((response) => response?.data);
  };

  const searchCharitiesFromCharityNavigator = async (term = '', limit = 50) => {
    if (stellate_api_url) {
      return await getUserHttpClient()
        .post(
          stellate_api_url,
          {
            query: `query PublicSearchFaceted($term: String!, $limit: Int!) {
              publicSearchFaceted(term: $term, result_size: $limit) {
                size
                from
                term
                result_count
                results {
                  ein
                  name
                  organization_url
                  street
                  street2
                  city
                  state
                  zip
                  country
                }
              }
            }`,
            variables: {
              term,
              limit,
            },
          },
          {
            headers: {
              'Stellate-Api-Token': stellate_api_token,
            },
          }
        )
        .then((response) => {
          const charityData = response.data.data;
          return charityData.publicSearchFaceted.results;
        })
        .catch(() => []);
    }
    return Promise.reject('Invalid Request.');
  };

  return {
    getCharities,
    getCharityDetails,
    saveCharityDetails,
    updateCharityDetails,
    deleteCharity,
    getCharityCategories,
    searchCharitiesFromCharityNavigator,
  };
};

export default useCharitiesApi;
