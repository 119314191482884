import { ITemplateDetails } from '../datatypes/Template';
import useAxiosService from '../services/axios.service';

const useTemplateApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const getTemplates = async (params?: any) => {
    return getUserHttpClient()
      .get('/templates', { params: params ? params : {} })
      .then((response) => {
        return response;
      });
  };

  const getTemplateDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/templates/' + id)
      .then((response) => response);
  };
  const saveTemplateDetails = async (data: ITemplateDetails) => {
    return await getUserHttpClient()
      .post('/templates/', data)
      .then((response) => response);
  };

  const updateTemplateDetails = async (data: ITemplateDetails, id: string) => {
    return await getUserHttpClient()
      .put('/templates/' + id, data)
      .then((response) => response);
  };

  const deleteTemplateData = async (id: string) => {
    return await getUserHttpClient()
      .delete('/templates/' + id)
      .then((response) => response?.data);
  };

  return {
    getTemplates,
    getTemplateDetails,
    deleteTemplateData,
    saveTemplateDetails,
    updateTemplateDetails,
  };
};

export default useTemplateApi;
