import useAxiosService from '../services/axios.service';

const useTestimonialApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //get testimonials list
  const getTestimonials = async () => {
    return await getUserHttpClient()
      .get('/testimonials')
      .then((response) => {
        return response;
      });
  };

  //get testimonial by id
  const getTestimonialDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/testimonials/' + id)
      .then((response) => response);
  };

  //save new testimonial
  const saveTestimonialDetails = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return await getUserHttpClient()
      .post('/testimonials/', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response);
  };

  //update old testimonial
  const updateTestimonialDetails = async (data: any, id: string) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });
    return await getUserHttpClient()
      .post('/testimonials/' + id, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Set the content type to FormData
        },
      })
      .then((response) => response);
  };

  //delete testimonial
  const deleteTestimonialData = async (id: string) => {
    return await getUserHttpClient()
      .delete('/testimonials/' + id)
      .then((response) => response);
  };

  return {
    getTestimonials,
    getTestimonialDetails,
    deleteTestimonialData,
    saveTestimonialDetails,
    updateTestimonialDetails,
  };
};

export default useTestimonialApi;
