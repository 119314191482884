import React from 'react';
import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle, Dtspinner } from '../../helpers/DataTableHelper';
import { IWillPagination } from '../../datatypes/MisssingWillSearch';
import {
  IDeathNoticePagination,
  IDeathNoticePayload,
  IDeathNoticeDetailRow,
  IDeathNoticeDetailColumn,
} from '../../datatypes/DeathNotice';
import { useAppDispatch } from '../../redux/hooks';
import { deleteDeathNotice } from '../../redux/slices/deathNoticeSlice';
import { PageOptions } from '../../constants/Common';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface IProps {
  deathNoticeList: IDeathNoticePayload['deathNoticeList'];
  setPagination: React.Dispatch<React.SetStateAction<IDeathNoticePagination>>;
}

const DeathNoticeList = ({ deathNoticeList, setPagination }: IProps) => {
  const dispatch = useAppDispatch();
  const columns: IDeathNoticeDetailColumn[] = [
    {
      name: 'Name',
      selector: (row: IDeathNoticeDetailRow) => row['name'],
      sortable: true,
      cell: (row: IDeathNoticeDetailRow) =>
        `${row.first_name} ${row.middle_name ? row.middle_name : ''} ${
          row.last_name
        }`.trim(),
    },
    {
      name: 'Location',
      selector: (row: IDeathNoticeDetailRow) => row['location'],
      sortable: true,
    },
    {
      name: 'Birth Date',
      selector: (row: IDeathNoticeDetailRow) => row['birthdate'],
      sortable: true,
      right: true,
    },
    {
      name: 'Death Date',
      selector: (row: IDeathNoticeDetailRow) => row['deathdate'],
      sortable: true,
      right: true,
    },
    {
      name: 'Created On',
      selector: (row: IDeathNoticeDetailRow) => row['posted_date'],
      sortable: true,
      right: true,
      cell: (row: IDeathNoticeDetailRow) => (
        <span className="white-space-pre">
          {moment
            .utc(moment(row['posted_date']))
            .local()
            ?.format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
    },
    {
      name: 'Actions',
      selector: (row: IDeathNoticeDetailRow) => row['actions'],
      cell: (row: IDeathNoticeDetailRow) => (
        <div className="mx-auto" style={{ display: 'flex', gap: '10px' }}>
          <Link
            title="View"
            className="btn btn-secondary btn-sm"
            to={`/death-notices/${row?.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
          <button
            title="Delete"
            className="btn btn-danger btn-sm"
            key="delete"
            onClick={() => {
              deleteDeathNoticeDetail(row);
            }}
          >
            <i className="fa fa-trash"></i>
          </button>
        </div>
      ),
      center: true,
    },
  ];

  const deleteDeathNoticeDetail = async (row: IDeathNoticeDetailRow) => {
    dispatch(deleteDeathNotice(String(row.id)));
  };

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Header className="d-flex justify-content-between align-items-center">
            <h2>Death Notices Lists</h2>
          </Card.Header>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={deathNoticeList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              columns={columns}
              paginationTotalRows={deathNoticeList?.data?.total}
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
              data={deathNoticeList.data?.data}
              paginationDefaultPage={deathNoticeList.data.current_page}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default DeathNoticeList;
