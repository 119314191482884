import React from 'react';
import DataTable from 'react-data-table-component';
import { Card } from 'react-bootstrap';
import { dtCustomStyle, Dtspinner } from '../../helpers/DataTableHelper';
import { IWillPagination } from '../../datatypes/MisssingWillSearch';
import {
  IInvoicePayload,
  IInvoiceDetailRow,
  IInvoiceDetailColumn,
  IInvoicePagination,
} from '../../datatypes/Invoice';
import { formatLabel } from '../../helpers/common';
import { PageOptions } from '../../constants/Common';
import moment from 'moment';
import { Link } from 'react-router-dom';

interface IProps {
  invoiceList: IInvoicePayload['invoiceList'];
  setPagination: React.Dispatch<React.SetStateAction<IInvoicePagination>>;
}

const InvoiceList = ({ invoiceList, setPagination }: IProps) => {
  const columns: IInvoiceDetailColumn[] = [
    {
      name: 'Actions',
      selector: (row: IInvoiceDetailRow) => row['actions'],
      width: '5.5rem',
      cell: (row: IInvoiceDetailRow) => (
        <div className="mx-auto">
          <Link
            title="View"
            className="btn btn-secondary btn-sm"
            to={`/invoices/${row?.id}/view`}
            key="view"
          >
            <i className="fa fa-eye"></i>
          </Link>
        </div>
      ),
      right: true,
    },
    {
      name: 'Invoice Number',
      selector: (row: IInvoiceDetailRow) => row['invoice_number'],
      sortable: true,
      width: '11rem',
    },
    {
      name: 'Transaction Id',
      selector: (row: IInvoiceDetailRow) => row['transaction_id'],
      sortable: true,
      width: '10rem',
    },
    {
      name: 'Billed To',
      selector: (row: IInvoiceDetailRow) => row['customer_name'],
      sortable: true,
    },
    {
      name: 'Price',
      selector: (row: IInvoiceDetailRow) => row['price'],
      sortable: true,
      right: true,
      width: '6rem',
    },
    {
      name: 'Payment Type',
      selector: (row: IInvoiceDetailRow) => row['payment_type'],
      sortable: true,
      center: true,
      cell: (row: IInvoiceDetailRow) => (
        <div>{formatLabel(row.payment_type)}</div>
      ),
    },
    {
      name: 'Payment Status',
      selector: (row: IInvoiceDetailRow) => row['payment_status'],
      sortable: true,
      center: true,
      cell: (row: IInvoiceDetailRow) => {
        switch (row?.payment_status) {
          case 'approved':
            return <span className="badge badge-success">Approved</span>;
          case 'pending':
            return <span className="badge badge-secondary">Pending</span>;
          case 'failed':
            return <span className="badge badge-danger">Failed</span>;
        }
      },
    },

    {
      name: 'Payment date',
      selector: (row: IInvoiceDetailRow) => row['payment_date'],
      sortable: true,
      right: true,
      cell: (row: IInvoiceDetailRow) => (
        <span className="white-space-pre">
          {moment
            .utc(new Date(row['payment_date']))
            .local()
            .format('MMM DD, YYYY[\r\n]h:mm a')}
        </span>
      ),
    },
  ];

  return (
    <div className="row mt-3">
      <div className="col-lg-12">
        <Card>
          <Card.Body className="">
            <DataTable
              className="dt-table"
              persistTableHead
              responsive
              progressPending={invoiceList.loading}
              keyField="id"
              progressComponent={<Dtspinner />}
              pagination
              paginationPerPage={50}
              paginationRowsPerPageOptions={PageOptions}
              striped
              paginationServer
              highlightOnHover
              columns={columns}
              paginationTotalRows={invoiceList?.data?.total}
              onChangeRowsPerPage={(new_per_page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  per_page: new_per_page,
                }))
              }
              data={invoiceList.data?.data}
              paginationDefaultPage={invoiceList.data.current_page}
              customStyles={dtCustomStyle}
              onChangePage={(page: number) =>
                setPagination((prev: IWillPagination) => ({
                  ...prev,
                  page: page,
                }))
              }
            />
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default InvoiceList;
