import useAxiosService from '../services/axios.service';

const usePricingApi = () => {
  const { getUserHttpClient } = useAxiosService();

  //api call for pricing list
  const getPricings = async (params?: any) => {
    return await getUserHttpClient()
      .get('/pricings', { params: params ? params : {} })
      .then((response) => {
        return response?.data;
      });
  };

  //api call for pricing by id
  const getPricingDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/pricings/' + id)
      .then((response) => {
        return response?.data;
      });
  };

  //api call for save pricing
  const savePricingDetails = async (data: any) => {
    const formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    return await getUserHttpClient().post('/pricings/', formData, {
      headers: {
        'Content-Type': 'multipart/form-data', // Set the content type to FormData
      },
    });
  };

  //api call for update pricing
  const updatePricingDetails = async (data: any, id: string) => {
    return await getUserHttpClient()
      .put('/pricings/' + id, data)
      .then((response) => response?.data);
  };

  //api call for delete pricing
  const deletePricingDetails = async (id: string) => {
    return await getUserHttpClient()
      .delete('/pricings/' + id)
      .then((response) => response?.data);
  };

  return {
    getPricings,
    getPricingDetails,
    savePricingDetails,
    updatePricingDetails,
    deletePricingDetails,
  };
};

export default usePricingApi;
