import useAxiosService from '../services/axios.service';

const useWillCreatorApi = () => {
  const { getUserHttpClient } = useAxiosService();
  const getWillCreators = async (params?: any) => {
    return getUserHttpClient()
      .get('/user-wills', { params: params ? params : {} })
      .then((response) => {
        return response;
      });
  };

  const getWillCreatorDetails = async (id: string) => {
    return await getUserHttpClient()
      .get('/user-wills/' + id)
      .then((response) => response);
  };

  const deleteWillCreatorData = async (id: string) => {
    return await getUserHttpClient()
      .delete('/user-wills/' + id)
      .then((response) => response);
  };

  return {
    getWillCreators,
    getWillCreatorDetails,
    deleteWillCreatorData,
  };
};

export default useWillCreatorApi;
