import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastSuccessMessage } from '../../components/common/ToastMessage';
import PageTitle from '../../components/common/PageTitle';
import { templateListPagination } from '../../constants/Template';
import {
  ITemplatePagination,
  IShowDeleteTemplateModal,
  ITemplatePayload,
} from '../../datatypes/Template';
import {
  resetTemplateData,
  fetchTemplates,
} from '../../redux/slices/templateSlice';
import Listing from '../../components/template/Listing';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';

const TemplateListing = () => {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState<ITemplatePagination>(
    templateListPagination
  );

  const [showDeleteModal, setShowDeleteModal] =
    useState<IShowDeleteTemplateModal>({
      show: false,
      idDetails: [],
    });

  const dispatch = useAppDispatch();

  const { templateList, deleteTemplateStatus } =
    useAppSelector<ITemplatePayload>((state) => state.template);

  const getTemplateListData = () => {
    dispatch(fetchTemplates({}));
  };

  useEffect(() => {
    getTemplateListData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(fetchTemplates({ params: pagination }));
    // eslint-disable-next-line
  }, [pagination]);

  useEffect(() => {
    if (deleteTemplateStatus.success) {
      toastSuccessMessage('Template deleted successfully!');
      setShowDeleteModal({
        show: false,
        idDetails: [],
      });
      dispatch(resetTemplateData());
      getTemplateListData();
    }
    // eslint-disable-next-line
  }, [deleteTemplateStatus]);

  return (
    <div className="email-templates">
      <PageTitle
        heading="Templates"
        buttonName="Generate Template"
        buttonClick={async () => {
          dispatch(resetTemplateData());
          navigate('/templates/new');
        }}
      />
      <div className="email-templates-content">
        <Listing
          templateList={templateList}
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          setPagination={setPagination}
          deleteTemplateDetail={deleteTemplateStatus}
        />
      </div>
    </div>
  );
};

export default TemplateListing;
