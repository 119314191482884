import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import useQuestionApi from '../../api/question.api';
import {
  IQuestionPayload,
  ISaveUpdateQuestion,
} from '../../datatypes/Question';

// initial / template state of questions redux slice
const initialState: IQuestionPayload = {
  questionList: {
    loading: false,
    error: false,
    success: false,
    data: {
      current_page: 0,
      total: 0,
      data: [],
    },
  },
  questionDetails: {
    loading: false,
    error: false,
    success: false,
    data: null,
  },
  saveUpdateStatus: {
    success: false,
    loading: false,
    error: false,
  },
  deleteQuestionStatus: {
    success: false,
    loading: false,
    error: false,
  },
};

// redux slice for questions
export const questionSlice = createSlice({
  name: 'questions',
  initialState,
  // reducers that don't require API Calls
  reducers: {
    resetQuestionsDetails: (state: IQuestionPayload) => {
      state.questionDetails = initialState.questionDetails;
      state.saveUpdateStatus = initialState.saveUpdateStatus;
      state.deleteQuestionStatus = initialState.deleteQuestionStatus;
    },
  },
  // reducers that require API calls
  extraReducers: (builder) => {
    builder
      .addCase(fetchQuestions.pending, (state) => {
        state.questionList.loading = true;
        state.questionList.error = false;
        state.questionList.success = false;
      })
      .addCase(fetchQuestions.fulfilled, (state, { payload }) => {
        state.questionList.data.data = payload.questions;
        state.questionList.data.current_page = payload.current_page ?? 0;
        state.questionList.data.total = payload.total ?? 0;
        state.questionList.loading = false;
        state.questionList.success = true;
      })
      .addCase(fetchQuestions.rejected, (state) => {
        state.questionList.loading = false;
        state.questionList.error = true;
      })
      .addCase(fetchQuestionDetails.pending, (state) => {
        state.questionDetails.loading = true;
        state.questionDetails.error = false;
        state.questionDetails.success = false;
      })
      .addCase(fetchQuestionDetails.fulfilled, (state, { payload }) => {
        state.questionDetails.data = payload;
        state.questionDetails.loading = false;
        state.questionDetails.success = true;
      })
      .addCase(fetchQuestionDetails.rejected, (state) => {
        state.questionDetails.loading = false;
        state.questionDetails.error = true;
      })
      .addCase(saveUpdateQuestion.pending, (state) => {
        state.saveUpdateStatus.loading = true;
        state.saveUpdateStatus.error = false;
        state.saveUpdateStatus.success = false;
      })
      .addCase(saveUpdateQuestion.fulfilled, (state) => {
        state.saveUpdateStatus.success = true;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(saveUpdateQuestion.rejected, (state) => {
        state.saveUpdateStatus.error = true;
        state.saveUpdateStatus.loading = false;
      })
      .addCase(deleteQuestion.pending, (state) => {
        state.deleteQuestionStatus.loading = true;
        state.deleteQuestionStatus.error = false;
        state.deleteQuestionStatus.success = false;
      })
      .addCase(deleteQuestion.fulfilled, (state) => {
        state.deleteQuestionStatus.success = true;
        state.deleteQuestionStatus.loading = false;
      })
      .addCase(deleteQuestion.rejected, (state) => {
        state.deleteQuestionStatus.error = true;
        state.deleteQuestionStatus.loading = false;
      });
  },
});

// Async API Hooks

/**
 * Fetch Questions List from API and save in redux store
 */
export const fetchQuestions = createAsyncThunk(
  'questions/fetchQuestions',
  async (_, { rejectWithValue }) => {
    const response = await useQuestionApi().getQuestions();
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Fetch Question Details from API and save in redux store
 */
export const fetchQuestionDetails = createAsyncThunk(
  'questions/fetchQuestionDetails',
  async (id: string, { rejectWithValue }) => {
    const response = await useQuestionApi().getQuestionDetails(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response.question;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

/**
 * Save / Update Question API and update it in redux store.
 */
export const saveUpdateQuestion = createAsyncThunk(
  'questions/saveUpdateQuestion',
  async (data: ISaveUpdateQuestion, { rejectWithValue }) => {
    const id = data.id;
    const response = id
      ? await useQuestionApi().updateQuestionDetails(data.data, String(id))
      : await useQuestionApi().saveQuestionDetails(data.data);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200) {
      return response?.data;
    } else {
      return rejectWithValue(response?.data);
    }
  }
);

/**
 * Delete Question API and update it in redux store.
 */
export const deleteQuestion = createAsyncThunk(
  'questions/deleteQuestion',
  async (id: string, { rejectWithValue }) => {
    const response = await useQuestionApi().deleteQuestionData(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.status === 200 || response.success === true) {
      return response;
    } else {
      return rejectWithValue(response.data);
    }
  }
);

export const { resetQuestionsDetails } = questionSlice.actions;

export default questionSlice.reducer;
